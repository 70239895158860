.unstyled {
  color: inherit;
}

button.unstyled {  /* Remove default styling from button */
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

a.unstyled {
  text-decoration: inherit;
}

a:hover {
  color: inherit;
}
