.btn-primary {
    color: white;
}

.btn-success {
    color: white;
}

.btn-primary:hover {
    color: white;
    background-color: #f34200;
    border-color: #e63e00;
}

.btn-success:hover {
    color: white;
    background-color: #f34200;
    border-color: #e63e00;
}

.btn-primary:focus {
    color: white;
    background-color: #f34200;
    border-color: #e63e00;
}

.btn-success:focus {
    color: white;
    background-color: #f34200;
    border-color: #e63e00;
}
