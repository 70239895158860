// 1. Configuration and Variables
$forge-dark-bg: #1a1a1a;
$forge-light-bg: #ffffff; // Updated to a slightly warmer off-white to reduce eye strain
$forge-accent: #e65100; // Slightly darker orange for better contrast
$forge-accent-hover: #ff6d00; // Brighter hover state for visual feedback
$forge-hot-metal-glow: rgba(230, 81, 0, 0.3); // Hot metal glow color
$forge-card-border: #1d1e1f; // Darker border, but not quite black
$forge-text-primary: #212529; // Dark text for contrast
$forge-text-secondary: #495057; // Dark secondary text

// Theme Colors
$primary: $forge-accent;
$success: $forge-accent;
$color-mode-type: media-query;

// Custom Properties
:root {
  // Shared properties
  --cow-accent: #{$forge-accent};
  --cow-accent-hover: #{$forge-accent-hover};
  --cow-bs-primary-rgb: #{red($primary)}, #{green($primary)}, #{blue($primary)};
  
  // Light mode defaults
  --cow-bg: #{$forge-light-bg};
  --bs-body-bg: #{$forge-light-bg};
  --cow-card-gradient: linear-gradient(145deg, #ffffff, #f0ebe5); // Warmer metallic card gradient
  --cow-card-border: #{$forge-card-border}; // Darker border for cards
  --cow-text-primary: #{$forge-text-primary}; // Dark text for contrast
  --cow-text-secondary: #{$forge-text-secondary}; // Dark secondary text
  --cow-component-bg: #ffffff; // White component background
  --cow-input-bg: #fffdfb; // Slightly warm input background to reduce eye strain
  --cow-input-border: #ced4da; // Standard input border
  --cow-shadow-color: rgba(0, 0, 0, 0.15); // Slightly stronger shadow
  --cow-glow-color: #{$forge-hot-metal-glow}; // Hot metal glow
  --cow-hot-metal-inner: rgba(255, 160, 120, 0.2); // Inner hot metal glow
  --cow-hot-metal-outer: rgba(230, 81, 0, 0.4); // Outer hot metal glow
  --cow-metallic-highlight: rgba(255, 255, 255, 0.7); // For metallic highlights
  --cow-metallic-shadow: rgba(0, 0, 0, 0.1); // For metallic shadows
  --cow-focus-outline: rgba(230, 81, 0, 0.5); // Focus outline for accessibility
  
  // Bootstrap border variables for light mode
  --bs-border-color: #{$forge-card-border}; // Update Bootstrap border color
  --bs-border-color-translucent: rgba(52, 58, 64, 0.175); // Translucent version
  --bs-card-border-color: var(--cow-card-border);
  --bs-card-cap-bg: rgba(52, 58, 64, 0.03);
  --bs-border-width: 1px;
  --cow-table-bg: #f7f5f2; // Warm gray that's between white and gray
  --cow-table-stripe-bg: #f0ebe5; // Stripe color for tables
  --cow-table-hover-bg: rgba(230, 81, 0, 0.05); // Very light orange tint on hover
}

// Dark mode overrides
@media (prefers-color-scheme: dark) {
  :root {
    --cow-bg: #{$forge-dark-bg};
    --bs-body-bg: #{$forge-dark-bg};
    --cow-card-gradient: linear-gradient(145deg, #2a2a2a, #232323);
    --cow-card-border: #444;
    --cow-text-primary: #fff;
    --cow-text-secondary: #aaa;
    --cow-component-bg: #2a2a2a;
    --cow-input-bg: #1a1a1a;
    --cow-input-border: #444;
    --cow-shadow-color: rgba(0, 0, 0, 0.3);
    --cow-glow-color: rgba(230, 81, 0, 0.2);
    --cow-focus-outline: rgba(255, 120, 60, 0.6); // Focus outline for accessibility
    
    // Bootstrap border variables for dark mode
    --bs-border-color: #444;
    --bs-border-color-translucent: rgba(68, 68, 68, 0.175);
    --bs-card-border-color: var(--cow-card-border);
    --bs-card-cap-bg: rgba(68, 68, 68, 0.03);
  }
}

// Reduced motion preference
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
  
  .hot-metal, .forge-glow, .card:hover, .crucible-element:hover {
    animation: none !important;
    box-shadow: 0 2px 4px var(--cow-shadow-color) !important;
  }
}

// 2. Required Bootstrap imports
@import "~bootstrap/scss/bootstrap.scss";

// 3. Base Styles
body {
  background: var(--cow-bg);
  color: var(--cow-text-primary);
}

// 4. Component Styles
.card {
  background: var(--cow-card-gradient);
  border-color: var(--cow-card-border);
  color: var(--cow-text-primary);
  
  &-title {
    font-family: 'Arial Black', sans-serif;
    margin-top: 10px;
    font-size: 18px;
    position: relative;
  }


  // Card column variants
  &-columns {
    @include make-col-ready();

    @include media-breakpoint-up(xs) { @include make-col(6); }
    @include media-breakpoint-up(md) { @include make-col(4); }
    @include media-breakpoint-up(lg) { @include make-col(3); }
    @include media-breakpoint-up(xl) { @include make-col(2.4); }
    @include media-breakpoint-up(xxl) { @include make-col(2); }
  }

  &-columns-4-max {
    @include make-col-ready();

    @include media-breakpoint-up(xs) { @include make-col(6); }
    @include media-breakpoint-up(sm) { @include make-col(4); }
    @include media-breakpoint-up(md) { @include make-col(3); }
  }

  &-columns-5-max {
    @include make-col-ready();

    @include media-breakpoint-up(xs) { @include make-col(6); }
    @include media-breakpoint-up(md) { @include make-col(4); }
    @include media-breakpoint-up(lg) { @include make-col(3); }
    @include media-breakpoint-up(xl) { @include make-col(2.4); }
  }
}

// Form Controls
.form-control {
  background-color: var(--cow-input-bg);
  border-color: var(--cow-input-border);
  color: var(--cow-text-primary);
  padding: 0.625rem 0.75rem;
  transition: all 0.2s ease;
  
  &:focus {
    background-color: var(--cow-input-bg);
    border-color: var(--cow-accent);
    color: var(--cow-text-primary);
    box-shadow: 0 0 0 0.25rem rgba(var(--cow-bs-primary-rgb), 0.25);
  }
}

// Buttons
.btn-primary {
  background: linear-gradient(135deg, var(--cow-accent), var(--cow-accent-hover));
  border: none;
  box-shadow: 0 2px 4px var(--cow-shadow-color);
  font-weight: 500;
  padding: 0.5rem 1.25rem;
  
  &:hover {
    background: linear-gradient(135deg, var(--cow-accent-hover), var(--cow-accent));
    transform: translateY(-1px);
    box-shadow: 0 4px 8px var(--cow-shadow-color);
  }
  
  &:active {
    transform: translateY(1px);
  }
}

// Forge-style glowing animation
@keyframes forge-glow {
  0% { box-shadow: 0 0 5px var(--cow-glow-color); }
  50% { box-shadow: 0 0 20px var(--cow-glow-color); }
  100% { box-shadow: 0 0 5px var(--cow-glow-color); }
}

.forge-glow {
  animation: forge-glow 3s infinite;
}

// Modal styles
.modal {
  background: var(--cow-card-gradient);
  
  &-content {
    background: var(--cow-card-gradient);
    border-color: var(--cow-card-border);
  }
  
  &-header {
    border-bottom-color: var(--cow-accent);
  }
  
  &-footer {
    border-top-color: var(--cow-card-border);
  }
}

// I named these "aspect-*" because they work different than bootstrap's ratio-* classes
.aspect-1x1 {
  aspect-ratio: 1 / 1;
}

.aspect-xl-4x3 {
  @include media-breakpoint-up(xl) {
    aspect-ratio: 4 / 3;
  }
}

.aspect-md-unset {
  @include media-breakpoint-up(md) {
    aspect-ratio: unset;
  }
}

// Metallic effects for light mode
@media (prefers-color-scheme: light) {
  // Hot metal card effect
  .card {
    // Enhanced metallic gradient with more pronounced texture
    background: linear-gradient(145deg, #ffffff, #e8e3dc);
    position: relative;
    transition: all 0.3s ease;
    
    // Add brushed metal effect on hover
    &:hover {
      background: linear-gradient(145deg, #f8f8f8, #e0dbd4);
      box-shadow: 
        0 0 0 1px var(--cow-card-border),
        0 0 8px 2px var(--cow-hot-metal-inner),
        0 0 15px 0 var(--cow-hot-metal-outer);
    }
    
    // Embossed text effect for card titles
    &-title {
      text-shadow: 1px 1px 1px var(--cow-metallic-highlight), 
                  -1px -1px 1px var(--cow-metallic-shadow);
      letter-spacing: 0.5px;
    }
  }
  
  // Hot metal keyframes animation
  @keyframes hot-metal-pulse {
    0% { box-shadow: 0 0 5px 1px var(--cow-hot-metal-inner), 0 0 10px 0 var(--cow-hot-metal-outer); }
    50% { box-shadow: 0 0 8px 2px var(--cow-hot-metal-inner), 0 0 15px 0 var(--cow-hot-metal-outer); }
    100% { box-shadow: 0 0 5px 1px var(--cow-hot-metal-inner), 0 0 10px 0 var(--cow-hot-metal-outer); }
  }
  
  // Class for elements that should always have the hot metal glow
  .hot-metal {
    animation: hot-metal-pulse 3s infinite;
  }
  
  // Metallic buttons with hot metal glow on hover
  .btn-primary {
    background: linear-gradient(135deg, var(--cow-accent), var(--cow-accent-hover));
    border: none;
    box-shadow: 
      inset 0 1px 0 var(--cow-metallic-highlight),
      0 2px 4px var(--cow-shadow-color);
    
    &:hover {
      box-shadow: 
        inset 0 1px 0 var(--cow-metallic-highlight),
        0 0 8px 2px var(--cow-hot-metal-inner),
        0 0 15px 0 var(--cow-hot-metal-outer);
    }
  }
  
  // Metallic inputs
  .form-control {
    box-shadow: 
      inset 0 1px 3px var(--cow-metallic-shadow),
      0 1px 0 var(--cow-metallic-highlight);
    
    &:focus {
      box-shadow: 
        inset 0 1px 3px var(--cow-metallic-shadow),
        0 0 0 1px var(--cow-accent),
        0 0 8px 0 var(--cow-hot-metal-inner);
    }
  }
}

// Crucible-themed section headers
h1:not(.plain-header), h2:not(.plain-header), .section-title {
  position: relative;
  padding-bottom: 0.5rem;
  color: var(--cow-text-primary); // Ensuring good contrast
  width: fit-content;
  
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 110%;
    height: 3px;
    background: linear-gradient(90deg, var(--cow-accent), transparent);
  }
}

// Update the crucible-element class with hot metal glow
.crucible-element {
  position: relative;
  border-radius: 6px;
  background: var(--cow-card-gradient);
  padding: 1.5rem;
  box-shadow: 
    inset 0 1px 0 var(--cow-metallic-highlight),
    0 2px 6px var(--cow-shadow-color);
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 
      inset 0 1px 0 var(--cow-metallic-highlight),
      0 0 8px 2px var(--cow-hot-metal-inner),
      0 0 15px 0 var(--cow-hot-metal-outer);
  }
}

@keyframes forge-glow {
  0% { box-shadow: 0 0 5px var(--cow-hot-metal-inner); }
  50% { box-shadow: 0 0 20px var(--cow-hot-metal-outer); }
  100% { box-shadow: 0 0 5px var(--cow-hot-metal-inner); }
}

table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  
  th {
    font-weight: 600;
    text-align: left;
    border-bottom: 2px solid var(--cow-accent);
    color: var(--cow-text-primary);
    padding: 0.75rem;
  }
  
  td {
    padding: 0.75rem;
    border-bottom: 1px solid var(--cow-input-border);
  }
  
  tr:hover {
    background-color: rgba(var(--cow-bs-primary-rgb), 0.05);
  }
}

// Override Bootstrap table variables for better light/dark mode distinction
.table {
  // Light mode table styles
  @media (prefers-color-scheme: light) {
    --bs-table-accent-bg: #f7f5f2; // Same as bg to ensure consistent coloring
    --bs-table-striped-bg: #f0ebe5; // Slightly darker warm gray for striped rows
    --bs-table-hover-bg: rgba(230, 81, 0, 0.05); // Very light orange tint on hover
  }
}

// Component Imports
@import './iconSizes.scss';
@import './icons.scss';
@import './links.scss';
@import './dragHandle.scss';
@import './creationCard.scss';
@import './hoverShadow.scss';
@import './input_validation.scss';
@import './unstyled.scss';
@import './button_text.scss';
@import './scrollBehavior.scss';
@import './smallContainer.scss';
@import './blockquote.scss';
@import './modal.scss';