@each $breakpoint in map-keys($grid-breakpoints) {
  @for $i from 2 through 10 {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .material-design-icon.icon#{$infix}-#{$i}x {

        height: #{$i}em;
        width: #{$i}em;

        > .material-design-icon__svg {
          height: #{$i}em;
          width: #{$i}em;
        }
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @for $i from 1 through 10 {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .material-design-icon.icon#{$infix}-1-point-#{$i}x {

        height: #{1 + $i * 0.1}em;
        width: #{1 + $i * 0.1}em;

        > .material-design-icon__svg {
          height: #{1 + $i * 0.1}em;
          width: #{1 + $i * 0.1}em;
        }
      }
    }
  }
}
