.creation-card {
    height: 100%;

    /* Center contents */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.creation-card svg {
    max-width: 25%;
    margin: 1em;
}

.creation-card span {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.5em;
    transition: color 0.4s ease-out;
}
