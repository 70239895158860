.small-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.small-form-box {
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  border-radius: .3rem;
}
