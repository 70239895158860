.hover-shadow {
  /* Start with 0 opacity */
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.5s ease-out;
}

.hover-shadow:hover, button:focus .hover-shadow {
    /* Transition to 50% opacity */
    background-color: rgba(0, 0, 0, .5);
    color: white;
}

.hover-shadow svg {
    fill: rgb(150, 150, 150);
    transition: fill 0.4s ease-out;
}

.hover-shadow:hover span {
  color: rgba(255,255,255, 0.8) !important;
}

.hover-shadow:hover svg, button:focus .hover-shadow {
    fill: rgba(255,255,255, 0.8);
}
