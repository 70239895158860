.hoverable-icon {
  cursor: pointer;
  color: grey;
}

.hoverable-icon:hover {
  color: #5a5a5a;  /* Slightly darker when hovered over */
}

.material-design-icon {
  display: inline-flex;
  position: relative;
  align-self: center;
  height: 1em;
  width: 1em;
}

.material-design-icon > .material-design-icon__svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
  position: absolute;
}

.material-design-icon.icon-smaller {
  height: .75em;
  width: .75em;
}

.material-design-icon.icon-smaller > .material-design-icon__svg {
  height: .75em;
  width: .75em;
}

/*

The following is the default styling from vue-material-design-icons for
comparison with our version.

.material-design-icon {
  display: inline-flex;
  align-self: center;
  position: relative;
  height: 1em;
  width: 1em;
}

.material-design-icon > .material-design-icon__svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
  position: absolute;
  bottom: -0.125em;  // We removed this to make things line up better
}
*/
